import APIRequest from '@/lib/APIRequest';
import { useAuth } from './use-auth';
import { fetcherFn } from 'swr/dist/types';
import { useTranslation } from 'react-i18next';

export interface UseAuthRequestProps<T = any> {
  api: () => Promise<APIRequest>;
  fetcher: fetcherFn<T>;
}

export const useAuthRequest = (): UseAuthRequestProps => {
  const { t } = useTranslation();
  const { getCredentials } = useAuth();
  const api = async (): Promise<APIRequest> => {
    const token = (await getCredentials())?.getAccessToken()?.getJwtToken();
    return new APIRequest().setErrorMessage(t('common.operation.error')).setAuthToken(token);
  };
  const fetcher = async (key: string): Promise<any> => {
    return await (await api()).setAPI(key).fetchData();
  };
  return { api, fetcher };
};
